// stylelint-disable no-empty-source

@import "_main";

// Gulp looks for variables in the following files in the specified order.
// 1. In the NodeJS package X-Net Rocket SCSS _variables.scss
// 2. In the project.json in the project root
// 3. In the custom_variables.scss
@import "_custom_variables";
@import "_breadcrumb";

//-----------------------------------------------------------------------------
// RESPONSIVE NAVBAR
@media (max-width: rem(991)) {
  .navbar-x-net {
    .nav-item.dropdown {
      text-align: center;
      font-size: 1.1rem;

      &:first-child {
        border-top: rem(1) solid #e6e6e6;
      }

      &:not(:last-child) {
        border-bottom: rem(1) solid #e6e6e6;
      }

      .icon {
        width: 1.6rem;
        height: 1.6rem;
      }
    }
  }
}

.navbar-x-net {
  .nav-item {
    &.active {
      .nav-link {
        font-weight: bold;
      }

      &::after {
        border: none;
      }
    }

    .nav-link {
      &.fix-text {
        &:focus,
        &:hover {
          color: var(--x-navbar-color);

          .icon {
            fill: var(--x-navbar-color);
          }
        }
      }
    }
  }
}

//------------------------------------------------------------------------------
// LOGIN-AS
.fixed_alert_container {
  background-image: linear-gradient(to bottom, #FCF8E3 0%, #F8EFC0 100%);
  background-repeat: repeat-x;
  border-color: #F5E79E;
  font-size: rem(15);
  padding: rem(9);
}

.svg-inline {
  height: 1.25em;
  vertical-align: -0.225em;
  width: 1.25em;
}

.border-bottom-primary {
  border-bottom: rem(1) solid $primary;
}

.border-2 {
  border-width: rem(2);
}

.border-3 {
  border-width: rem(3);
}

.bg-main {
  background-color: $bgColor;
}

.icon-md {
  width: rem(20);
  height: rem(20);
}

.icon-xl {
  width: 5rem;
  height: 5rem;
}

.box-shadow {
  box-shadow: 0 rem(10) rem(18) rgba(0, 0, 0, 0.04), 0 rem(-2) rem(10) rgba(0, 0, 0, 0.04);
}

.document-list {
  .document-list-item {
    padding: 0.8rem;

    & , &:hover {
      color: $dark;
    }

    &:not(:last-child) {
      border-bottom: rem(1) solid #e6e6e6;
    }

    &:hover {
      background-color: #f4f4f4;
    }
  }
}

// #########################################################################
// slider switch
.switch {
  position: relative;
  display: inline-block;
  width: rem(40);
  height: rem(21);

  /* Hide default HTML checkbox */
  input {
    opacity: 0;
    width: 0;
    height: 0;
    outline: none;
    box-shadow: none;
  }
}


/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  &::before {
    position: absolute;
    content: "";
    height: rem(14);
    width: rem(14);
    left: rem(4);
    bottom: rem(4);
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  /* Rounded sliders */
  &.round {
    border-radius: rem(18);

    &::before {
      border-radius: 50%;
    }
  }
}

input {
  &:checked + .slider {
    background-color: darken($primary, 15%);

    &::before {
      -webkit-transform: translateX(rem(18));
      -ms-transform: translateX(rem(18));
      transform: translateX(rem(18));
    }
  }

  &:focus + .slider {
    box-shadow: 0 0 rem(1) $primary;
  }
}
// ###########################################################################

@media screen and (max-width: rem($break-sm)) {
  .settings-row {
    flex-direction: column;

    > div:nth-child(1) {
      order: 2;
    }

    > div:nth-child(2) {
      order: 1;
      margin-bottom: 0.8rem;
    }
  }
}


.svg-stroke {
  stroke: $gray-600;
}

#id_popup_modal {
  .modal-content {
    max-height: 20rem;

    .modal-body {
      overflow-y: auto;
    }
  }
}

.modal-header {
  &::after {
    background: unset;
    background-color: $primary;
  }
}

.list-styled {
  list-style: escape-svg(url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><rect fill='#{$gray-600}' height='7' width='7' x='0' y='1'/></svg>"));
}

.ellipse {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;

  &:hover {
    overflow: unset;
    text-overflow: unset;
    white-space: unset;
  }
}

.modal-error-xl {
  .modal-content {
    max-width: 90%;
  }
}


.radius-3 {
  border-radius: rem(3);
}

.select2-search__field {
  border: var(--x-border-width) solid var(--x-input-border-color);

  &:focus-visible {
    outline: 0;
    border-color: var(--x-input-focus-border);
    box-shadow: 0 0 0 rem(3) var(--x-input-focus-box-shadow-color);
  }
}

.ms-container {
  .ms-list.ms-focus {
    border: var(--x-border-width) solid var(--x-input-border-color);
    outline: 0;
    border-color: var(--x-input-focus-border);
    box-shadow: 0 0 0 rem(3) var(--x-input-focus-box-shadow-color);
  }
}

.assign-company-list-container {
  .form-group {
    display: flex;
    align-items: center;

    .label {
      flex: 1;
    }

    .custom-select {
      margin-left: 2rem;
      flex: 2;
    }
  }
}
