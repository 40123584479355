$project-name: MA-Portal;
$brand-1: #f7f446;
$brand-2: #006893;
$info: #17A2B8;
$favicon-bg-color: #ffffff;
$email-h1-color: #000;
$email-h2-color: #000;
$email-h3-color: #000;
$email-h4-color: #000;
$email-h5-color: #000;
$email-h6-color: #000;
$email-anchor-color: #006893;
$email-anchor-hover-color: #004993;
$email-anchor-active-color: #006893;
$email-anchor-visited-color: #002593;