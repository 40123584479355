.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
}

.breadcrumb-item {
  a {
    & , &:hover {
      color: $dark;
    }
  }

  + .breadcrumb-item {
    padding-left: 0.3rem;

    &::before {
      float: left;
      padding-right: 0.3rem;
      color: #6c757d;
      content: "/";
    }
  }

  &.active {
    color: #6c757d;
  }
}
